import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MDBIcon } from "mdbreact";
import "./shared.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import TableFilters from "./TableFilters";
import TableInlineEdits from "./TableInlineEdits";
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { withUserContext } from '../../contexts/UserContext';
import { Tooltip } from "@material-ui/core";
import { getFormattedDate } from "../../Common/Helper"
import SelectField from './SelectField/SelectField';

class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        var headings = this.props?.config?.headings;
        var colArr = {};
        var dataTypeArr = {};
        var selectOptionsArr = {};
        headings.forEach((heading, index) => {
            colArr[heading.headingLabel] = "";
            dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType;
            if (heading.inputType == "select" || heading.inputType === "searchselect")
                selectOptionsArr[this.props?.config?.dataNodes[index]] =
                    heading.selectOptions;
        });
        this.state = {
            inputValues: colArr,
            filterValues: [],
            listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
            editInputValues: [],
            dataTypeValues: dataTypeArr,
            selectOptionValues: selectOptionsArr,
            newItem: {},
            currentFocusItem: null,
            openPayeeModal: null,
            isFetchingSeason: false,
            openSyncLicensorModal:null,
            openCueSheetPubModal:null,
            openPerformerModal:null,
        };
    }

    componentDidMount() {
        //var headings = this.props?.config?.headings;
        // var colArr = {};
        // var dataTypeArr = {};
        // var selectOptionsArr = {};
        // headings.forEach((heading) => {
        //     colArr[heading.headingLabel] = "";
        //     dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType ;
        //     if(heading.inputType == "select")
        //     selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
        // });
        // this.setState({
        //     inputValues : colArr,
        //     filterValues: [],
        //     listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
        //     dataTypeValues: dataTypeArr,
        //     selectOptionValues: selectOptionsArr
        // });

    }
    compoentDidMount() {
        if (this.props?.defaultValuePopulation) {
            let newInputValues = this.state?.newItem && { ...this.state?.newItem } || {}
            if (this.props?.handleValuePopulation) {
                let valuesToAutoPopulate = this.props?.handleValuePopulation(this.props?.defaultValuePopulation?.['payee_remit_to'], "payee_remit_to");
                if (valuesToAutoPopulate) {
                    valuesToAutoPopulate?.map(item => {
                        newInputValues[item?.node] = item?.value || null;
                    })
                } else {
                    return
                }
            }
            newInputValues = { ...newInputValues, ...this.props?.defaultValuePopulation }
            this.setState({ newItem: newInputValues })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.list) !== JSON.stringify(this.props.list)) {
            this.setState({
                listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
                ...(!this.props?.defaultValuePopulation) && {newItem: null}
            });
        }
        if (this.props?.isURM) {
            if (this.props?.updatedListEdits !== prevProps?.updatedListEdits) {
                let listEdits = [...this.state.listEdits]
                listEdits[this.props?.urmIndex].searchSelectOptions = this.props.updatedListEdits
                this.setState({
                    listEdits
                })
            }
            if (this.props?.isFetchingSeason !== prevProps?.isFetchingSeason) {
                this.setState({ isFetchingSeason: this.props?.isFetchingSeason })
            }
        }
        this.props?.config?.headings?.map((heading, index) => {
            if (heading.inputType === "searchselect" || heading.inputType === "select") {
                if (prevState?.selectOptionValues[this.props?.config.dataNodes[index]] !== prevProps?.config?.headings[index].selectOptions) {
                    prevState.selectOptionValues[this.props.config.dataNodes[index]] =
                        heading.selectOptions;
                    this.setState({ selectOptionValues: prevState?.selectOptionValues })
                }
            }
        })
        if (prevProps?.updatedItemNodeValue !== this.props?.updatedItemNodeValue) {
            this.updateTableInlineValues()
        }
        if (prevProps?.listUpdated !== this.props?.listUpdated) {
            this.setState({
                listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
            }, () => {
                this.props?.handleListUpdate();
            });
        }
        if (this.state.currentFocusItem && this.props?.idForFocus) {
            document.getElementById(this.state.currentFocusItem)?.focus();
            this.setState({ currentFocusItem: null })
        }
        // for autoPopulate default payeee refer clearance check request
        if (this.props?.defaultValuePopulation && JSON.stringify(prevProps?.defaultValuePopulation) !== JSON.stringify(this.props?.defaultValuePopulation)) {
            // this.onChangeNewItemHandler(this.props?.defaultValuePopulation,null,'payee_remit_to')
            let newInputValues = this.state?.newItem && { ...this.state?.newItem } || {}
            if (this.props?.handleValuePopulation) {
                let valuesToAutoPopulate = this.props?.handleValuePopulation(this.props?.defaultValuePopulation?.['payee_remit_to'], "payee_remit_to");
                if (valuesToAutoPopulate) {
                    valuesToAutoPopulate?.map(item => {
                        newInputValues[item?.node] = item?.value || null;
                    })
                } else {
                    return
                }
            }
            newInputValues = { ...newInputValues, ...this.props?.defaultValuePopulation }
            this.setState({ newItem: newInputValues })

        }
        else if (this.props?.defaultValuePopulation && this.state?.newItem && Object.keys(this.state?.newItem)?.length === 0) {
            let newInputValues = this.state?.newItem && { ...this.state?.newItem } || {}
            if (this.props?.handleValuePopulation) {
                let valuesToAutoPopulate = this.props?.handleValuePopulation(this.props?.defaultValuePopulation?.['payee_remit_to'], "payee_remit_to");
                if (valuesToAutoPopulate) {
                    valuesToAutoPopulate?.map(item => {
                        newInputValues[item?.node] = item?.value || null;
                    })
                } else {
                    return
                }
            }
            newInputValues = { ...newInputValues, ...this.props?.defaultValuePopulation }
            this.setState({ newItem: newInputValues })
        } 
    }

    componentWillReceiveProps(prevProps, prevState) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props?.list)) {
            this.setState({ listEdits: this.props.list });
        }
    }

    onFlyUpdate = (onFlyAddData, rowInfo) => {
        //this.props?.searchString(onFlyAddData?.slice(0,3), null)
        let columnName = rowInfo?.split('-')[2]
        let columnNameDefault = columnName+'_name'
        if (rowInfo?.split('-')[1] === 'new') {
            let newItem = this.state.newItem ? { ...this.state.newItem } : {}
            newItem[columnName] = {
                'value': onFlyAddData?.value,
                'text': onFlyAddData?.text
            }
            newItem[columnNameDefault] =  onFlyAddData?.text
            this.setState({ newItem: newItem, openCueSheetPubModal:null, openPerformerModal:null })
        } else {
            let listEdits = [...this.state.listEdits]
            listEdits[rowInfo?.split('-')[1]][columnName] = {
                'value': onFlyAddData?.value,
                'text': onFlyAddData?.text
            }
            listEdits[rowInfo?.split('-')[1]][columnNameDefault] =  onFlyAddData?.text
            this.setState({ listEdits: listEdits, openCueSheetPubModal:null,openPerformerModal:null })
        }
    }

    getPayeeRemitTo = (payeeDetails) => {
        this.props?.getPayeeData()
        let payeeModalInfo = this.state.openPayeeModal
        if (payeeModalInfo?.split('-')[1] === 'new') {
            let newItem = this.state.newItem ? { ...this.state.newItem } : {}
            let remitToIndex = payeeModalInfo?.split('-')[0] === 'new' ? 0 : payeeDetails?.remits_to?.findIndex(item => item?.payee_remit_to_id == payeeModalInfo?.split('-')[3])
            newItem[payeeModalInfo?.split('-')[2]] = {
                'value': payeeDetails?.remits_to[remitToIndex]?.payee_remit_to_id,
                'text': `${payeeDetails?.payee_company ? payeeDetails?.payee_company : ' '} ${payeeDetails?.remits_to[remitToIndex]?.DBA ? payeeDetails?.remits_to[remitToIndex]?.DBA : ' '} ${payeeDetails?.tax ? payeeDetails?.tax : ' '} ${payeeDetails?.ssn ? payeeDetails?.ssn : ' '} ${payeeDetails?.other ? payeeDetails?.other : ' '} ${payeeDetails?.remits_to[remitToIndex]?.contact_name ? payeeDetails?.remits_to[remitToIndex]?.contact_name : ' '} ${payeeDetails?.remits_to[remitToIndex]?.address_1 ? payeeDetails?.remits_to[remitToIndex]?.address_1 : ' '}`
            }
            this.setState({ newItem: newItem })
        } else {
            let listEdits = [...this.state.listEdits]
            let remitToIndex = payeeModalInfo?.split('-')[0] === 'new' ? 0 : payeeDetails?.remits_to?.findIndex(item => item?.payee_remit_to_id == payeeModalInfo?.split('-')[3])
            listEdits[payeeModalInfo?.split('-')[1]][payeeModalInfo?.split('-')[2]] = {
                'value': payeeDetails?.remits_to[remitToIndex]?.payee_remit_to_id,
                'text': `${payeeDetails?.payee_company ? payeeDetails?.payee_company : ' '} ${payeeDetails?.remits_to[remitToIndex]?.DBA ? payeeDetails?.remits_to[remitToIndex]?.DBA : ' '} ${payeeDetails?.tax ? payeeDetails?.tax : ' '} ${payeeDetails?.ssn ? payeeDetails?.ssn : ' '} ${payeeDetails?.other ? payeeDetails?.other : ' '} ${payeeDetails?.remits_to[remitToIndex]?.contact_name ? payeeDetails?.remits_to[remitToIndex]?.contact_name : ' '} ${payeeDetails?.remits_to[remitToIndex]?.address_1 ? payeeDetails?.remits_to[remitToIndex]?.address_1 : ' '}`
            }
            this.setState({ listEdits: listEdits }, () => {
                if (this.props?.updateOptCheckRequest) {
                    if (this.props?.idForFocus) {
                        this.setState({ currentFocusItem: "txt-" + payeeModalInfo?.split('-')[2] + "-" + payeeModalInfo?.split('-')[1] + this.props?.idForFocus })
                    }
                    this.props?.updateOptCheckRequest(this.state.listEdits, payeeModalInfo?.split('-')[1], payeeModalInfo?.split('-')[2])
                }
            })
        }
    }

    handleOnlyIconColumn = (dataNode, index, resetField, mode = 'edit') => {
        if(dataNode === 'song_performer_id') {
            if (mode === 'edit') {
                let selectedRow = this.state.listEdits[index]
                this.setState({ openPerformerModal: selectedRow?.[resetField]?.value ? `${selectedRow?.[resetField]?.value}-${index}-${resetField}` : 'new-' + index + '-' + resetField })
            } else {
                this.setState({ openPerformerModal: this.state.newItem?.[resetField]?.value ? `${this.state.newItem?.[resetField]?.value}-new-${resetField}` : 'new' + '-new' + '-' + resetField })
            }
        } else if(dataNode === 'song_cue_sheet_publisher_id') {
            if (mode === 'edit') {
                let selectedRow = this.state.listEdits[index]
                this.setState({ openCueSheetPubModal: selectedRow?.[resetField]?.value ? `${selectedRow?.[resetField]?.value}-${index}-${resetField}` : 'new-' + index + '-' + resetField })
            } else {
                this.setState({ openCueSheetPubModal: this.state.newItem?.[resetField]?.value ? `${this.state.newItem?.[resetField]?.value}-new-${resetField}` : 'new' + '-new' + '-' + resetField })
            }
        } else if(dataNode === 'song_licensor_id' || dataNode === 'performer_licensor_id') {
            if (mode === 'edit') {
                let selectedRow = this.state.listEdits[index]
                this.setState({ openSyncLicensorModal: selectedRow?.[resetField]?.value ? `${selectedRow?.[resetField]?.value}-${index}-${resetField}` : 'new-' + index + '-' + resetField })
            } else {
                this.setState({ openSyncLicensorModal: this.state.newItem?.[resetField]?.value ? `${this.state.newItem?.[resetField]?.value}-new-${resetField}` : 'new' + '-new' + '-' + resetField })
            }
        } else {
            if (mode === 'edit') {
                let selectedRow = this.state.listEdits[index]
                this.setState({ openPayeeModal: selectedRow?.[resetField]?.value ? `${this.props?.allPayeeRemitTo?.find(item => item?.value === selectedRow?.[resetField]?.value)?.payee}-${index}-${resetField}-${selectedRow?.[resetField]?.value}` : 'new-' + index + '-' + resetField })
            } else {
                this.setState({ openPayeeModal: this.state.newItem?.[resetField]?.value ? `${this.props?.allPayeeRemitTo?.find(item => item?.value === this.state.newItem?.[resetField]?.value)?.payee}-new-${resetField}-${this.state.newItem?.[resetField]?.value}` : 'new' + '-new' + '-' + resetField })
            }
        }
    }


    updateTableInlineValues = () => {
        if (typeof (this.props?.updatedItemNodeValue?.index) === "undefined") {
            let newInputValues = { ...this.state.newItem };
            if (this.props?.updatedItemNodeValue?.length > 0) {
                this.props?.updatedItemNodeValue.map((item, index) => {
                    newInputValues[item?.node] = item?.value || null;
                    this.setState({ newItem: newInputValues })
                })
            } else {
                newInputValues[this.props?.updatedItemNodeValue?.node] = this.props?.updatedItemNodeValue?.value || null;
                this.setState({ newItem: newInputValues })
            }
        } else {
            let editInputValues = [...this.state.listEdits];
            editInputValues[this.props?.updatedItemNodeValue?.index][this.props?.updatedItemNodeValue?.node] = this.props?.updatedItemNodeValue?.value || null;
            this.setState({ listEdits: editInputValues })
        }
    }

    addFilterChangeHandler = (index, name, value) => {
        var inputValues = this.state.inputValues;

        var filterValues = this.state.filterValues;
        inputValues[name] = "";
        filterValues.push(name + ": " + value);

        this.setState({
            inputValues: inputValues,
            filterValues: filterValues,
        });

        var filtersNone = false;
        if (filterValues.length == 0) filtersNone = true;
        this.props.applyFilter(filterValues, filtersNone);
    };

    filterChangeHandler = (index, name, value, filter) => {
        var inputValues = this.state.inputValues;
        inputValues[name] = value;
        this.setState({
            inputValues: inputValues,
        });
    };

    onChangeNewItemHandler = (value, index, nodeName) => {
        this.setState({ currentFocusItem: null })
        let newInputValues = { ...this.state.newItem }
        if (this.props?.updateTaxSSNVendor && nodeName === 'payee_remit_to') {
            newInputValues['tax_ssn'] = value?.tax_ssn || null
            newInputValues['vendor'] = value?.vendor || null
        }
        if (typeof value === "boolean") {
            newInputValues[nodeName] = value || false;
        } else {
            newInputValues[nodeName] = value || null;
        }
        if (this.props?.handleValuePopulation) {
            let valuesToAutoPopulate = this.props?.handleValuePopulation(value, nodeName, newInputValues);
            if (valuesToAutoPopulate) {
                valuesToAutoPopulate?.map(item => {
                    newInputValues[item?.node] = item?.value || null;
                })
            } else {
                return
            }
        }
        this.setState({ newItem: newInputValues }, () => {
            if (this.props?.isURM) {
                if (nodeName === 'show_id') {
                    this.props?.onShowChangeCallBack(newInputValues)
                }
            }
        })
    }

    handleAddNewItem = () => {
        if (this.props?.validateNewItem != undefined) {
            if (this.props?.validateNewItem(this.state.newItem)) {
                this.props?.handleAddNewItem(this.state.newItem)

                if (this.props?.config?.isClearFields) {
                    this.setState({ newItem: {} }, () => { })
                }
            }
        }
        else {
            this.props?.handleAddNewItem(this.state.newItem)

            if (this.props?.config?.isClearFields) {
                this.setState({ newItem: {} }, () => { })
            }
        }

    }

    handleInlineEditChange = (value, index, nodeName, type = null) => {
        this.setState({ currentFocusItem: null })
        var editInputValues = [...this.state.listEdits];
        if (this.props?.updateTaxSSNVendor && nodeName === 'payee_remit_to') {
            editInputValues[index]['tax_ssn'] = value?.tax_ssn || null
            editInputValues[index]['vendor'] = value?.vendor || null
        }
        if (typeof value === "boolean") {
            editInputValues[index][nodeName] = value || false
        } else {
            editInputValues[index][nodeName] = value || null;
        }
        this.setState({ listEdits: editInputValues }, () => {
            if (this.props?.updateOptCheckRequest) {
                if (this.props?.idForFocus) {
                    this.setState({ currentFocusItem: "txt-" + nodeName + "-" + index + this.props?.idForFocus })
                }
                this.props?.updateOptCheckRequest(this.state.listEdits, index, nodeName)
            }
            if (this.props?.updateBudgetDataInlineEdit) {
                if (this.props?.idForFocus) {
                    this.setState({ currentFocusItem: "txt-" + nodeName + "-" + index + this.props?.idForFocus })
                }
                this.props?.updateBudgetDataInlineEdit(this.state.listEdits, index, nodeName)
            }
            if (this.props?.isURM) {
                if (nodeName === 'show_id') {
                    this.props?.onShowChangeCallBack(editInputValues[index], 'edit', index)
                }
            }
        });
    };

    handleFilterDelete = (removeNode, index) => {
        var filterValues = this.state.filterValues;
        filterValues.splice(index, 1);
        this.setState({ filterValues: filterValues });
        var filtersNone = false;
        if (filterValues.length == 0) filtersNone = true;
        this.props.applyFilter(filterValues, filtersNone);
    };

    render() {
        const unhideIcon = (this.props?.division_id === undefined ? (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Clearance (Admin)', 'Super Admin'].includes(item.role_name))))
        : (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Clearance (Admin)', 'Super Admin'].includes(item.role_name) && item.division_id === this.props?.division_id))));          
        
        const unhideIconMS = (this.props?.division_id === undefined ? (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Supervisor'].includes(item.role_name))))
        : (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Supervisor'].includes(item.role_name) && item.division_id === this.props?.division_id)))); 
        return (
            <>
                <div>
                    <div
                        className={
                            this.props?.maxHeight
                                ? "tableContainer " + "max-height-" + this.props?.maxHeight
                                : "tableContainer"
                        }
                    >
                        <TableContainer
                            component={Paper}
                            className={
                                this.props?.tableMode == "light"
                                    ? "bg-white overflow-x-unset"
                                    : "bg-light overflow-x-unset"
                            }
                        >
                            <Table stickyHeader={this.props?.stickyHeader && !this.props?.userContext?.sideNavOpen ? true : false}
                                id={this.props.className + "-table"}
                                //stickyHeader
                                aria-label="collapsible table"
                                size="small"
                                style={{ tableLayout: "fixed" }}
                            >
                                <colgroup>
                                    {this.props?.config?.headings?.map((heading, index) => (
                                        <col
                                            key={"colWidth_" + index}
                                            width={
                                                heading.width
                                                    ? heading.width
                                                    : 100 / this.props?.config?.headings?.length + "%"
                                            }
                                        />
                                    ))}
                                </colgroup>
                                {this.props?.config?.isSearchBar ? (
                                    <>
                                        <TableHead class="searchHeaders">
                                            <TableRow key={this.props.className + "-searchHeaderRow"}>
                                                {this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell
                                                        key={
                                                            this.props.className +
                                                            "-" +
                                                            index +
                                                            "-" +
                                                            heading.headingLabel
                                                        }
                                                        className="searchHeaderRows"
                                                    >
                                                        {index === 0
                                                            ? "Search Results for :" +
                                                            '"' +
                                                            this.props.searchText +
                                                            '"'
                                                            : ""}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableHead class="searchHeadersSecond">
                                            <TableRow
                                                key={this.props.className + "-searchHeaderSecondRow"}
                                            >
                                                {this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell
                                                        key={this.props.className + "-" + index}
                                                        className="searchHeadersSecondRows"
                                                    >
                                                        {index === 0
                                                            ? this.props.resultsCount +
                                                            " search results for " +
                                                            "[" +
                                                            this.props.searchType +
                                                            "]"
                                                            : ""}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    </>
                                ) : null}
                                <TableHead
                                    id={this.props.className + "-tablehead"}
                                    className={
                                        this.props?.tableMode == "light" ? "bg-light" : "bg-dark"
                                    }
                                >
                                    {this.props?.config?.filters ? (
                                        <TableRow
                                            className={
                                                this.state.filterValues?.length > 0
                                                    ? "columnFilters d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
                                            <div style={{ display: "flex" }}>
                                                {this.state.filterValues &&
                                                    this.state.filterValues?.map((item, index) => (
                                                        <Chip
                                                            label={item}
                                                            onDelete={this.handleFilterDelete.bind(
                                                                this,
                                                                item,
                                                                index
                                                            )}
                                                        />
                                                    ))}
                                            </div>
                                            {/* </TableCell> */}
                                        </TableRow>
                                    ) : (
                                        <></>
                                    )}
                                    <TableRow>
                                        {this.props?.config?.headings?.map((heading, index) => (
                                            <TableCell
                                                key={this.props.className + "-" + index}
                                                style={{ padding: "4px" }}
                                                title={heading.headingLabel}
                                                className={
                                                    this.props?.tableMode == "light"
                                                        ? "bg-light FW800"
                                                        : "bg-dark FW800"
                                                }
                                            >
                                                {this.props?.config?.filters ? (
                                                    <>
                                                        <TableFilters
                                                            inputType={heading.inputType}
                                                            name={heading.headingLabel}
                                                            inputValue={
                                                                this.state.inputValues[heading.headingLabel]
                                                            }
                                                            filterChangeHandler={this.filterChangeHandler.bind(
                                                                this,
                                                                index,
                                                                heading.headingLabel
                                                            )}
                                                            addFilterChangeHandler={this.addFilterChangeHandler.bind(
                                                                this,
                                                                index,
                                                                heading.headingLabel
                                                            )}
                                                        />{" "}
                                                    </>
                                                ) : (
                                                    <> </>
                                                )}
                                                <span
                                                    className="action-icon"
                                                    onClick={() =>
                                                        this.props?.sortCallback(heading.headingLabel)
                                                    }
                                                >
                                                    <b>
                                                        {" "}
                                                        {heading.icon ? (
                                                            <div>
                                                                {heading.headingLabel}
                                                                <MDBIcon icon={heading.icon} />
                                                            </div>
                                                        ) : (
                                                            heading.headingLabel
                                                        )}{" "}
                                                    </b>
                                                </span>
                                            </TableCell>
                                        ))}
                                        {this.props?.config?.actions && (
                                            <TableCell
                                                key={
                                                    this.props.className +
                                                    "-" +
                                                    this.props?.config?.headings?.length
                                                }
                                                className={
                                                    this.props?.tableMode == "light"
                                                        ? "bg-light FW800"
                                                        : "bg-dark FW800"
                                                }
                                                style={{ padding: "4px" }}
                                            ></TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                {this.props?.isLoading ? (
                                    <div className="tableLoader">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <TableBody key={this.props.className + "body"}>
                                        {this.props?.list?.length > 0 &&
                                            this.props?.list?.map((dataItem, rowIndex) => (
                                                this.props?.postInitiated && (this.props?.postInitiated === dataItem[this.props.config.primaryKey]) ? <CircularProgress size={20} /> :
                                                    <TableRow
                                                        id={
                                                            this.props.className +
                                                            dataItem[this.props.config.primaryKey]
                                                        }
                                                        key={
                                                            this.props.className +
                                                            "-" +
                                                            dataItem[this.props.config.primaryKey] +
                                                            "-" +
                                                            rowIndex
                                                        }
                                                        className={this.props?.config?.highlightRow ? (dataItem?.batch_no === "None" && dataItem?.generated_date === null) ? "highlight_selected_row" : null : (this.props?.initialRightsFlag && this.props?.selectedIndex === rowIndex) ? "highlight_selected_row" : null}
                                                        onClick={this.props?.flag ? (e) => { this.props?.updatePayeeGrid(e, dataItem, rowIndex) } : this.props?.initialRightsFlag ? (e) => this.props?.initialRightsRowClick(e, dataItem, rowIndex) : null}
                                                    >
                                                        {this.props?.config?.dataNodes?.map((node, index) =>
                                                            node == "check" ? (
                                                                <TableCell className="FW800">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={dataItem[node]}
                                                                        disabled={this.props?.config?.disableSelectCheckbox}
                                                                        onClick={(event, newValue) => {
                                                                            dataItem[node] = event.target.checked;
                                                                            this.props.arrayCheckUncheck(
                                                                                event.target.checked,
                                                                                dataItem
                                                                            );
                                                                        }}
                                                                    ></input>
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell
                                                                    key={
                                                                        this.props.className +
                                                                        "-" +
                                                                        dataItem[this.props?.config?.primaryKey] +
                                                                        "-" +
                                                                        dataItem[node] +
                                                                        "-" +
                                                                        index
                                                                    }
                                                                    className={
                                                                        (this.props?.config?.borders?.includes(node)
                                                                            ? "border-left-1-secondary "
                                                                            : "") +
                                                                        (this.props?.config?.changeColourToRed?.includes(
                                                                            dataItem[node]
                                                                        )
                                                                            ? "textColorRed "
                                                                            : "") +
                                                                        (this.props?.config?.changeColourToGreen?.includes(
                                                                            node
                                                                        )
                                                                            ? "textColorGreen "
                                                                            : "") +
                                                                        //(this.props?.config?.dataNodes.length == index+1 ? "border-right-0 " : ""  ) +
                                                                        "border-ver-1-secondary padding-5 word-break"
                                                                    }
                                                                >
                                                                    {dataItem["editing"] &&
                                                                        this.props?.config?.inlineEdits?.some(e => e.dataNode === node) ? (
                                                                        <> </>
                                                                    ) : node == "groups" ? (
                                                                        dataItem[node]?.map((item) => (
                                                                            <div className="groupsStyles">{item}</div>
                                                                        ))
                                                                    ) : node == "label" ? (
                                                                        <div
                                                                            className="labelNames"
                                                                            onClick={(e) =>
                                                                                this.props.editData(dataItem, node)
                                                                            }
                                                                        >
                                                                            {dataItem[node]}
                                                                        </div>
                                                                    ) : this.props?.config?.hyperlinks?.includes(
                                                                        node
                                                                    ) ? (
                                                                        <a
                                                                            href="javascript:void(0);"
                                                                            onClick={(e) =>
                                                                                this.props.hyperLinkNavigationCallback(
                                                                                    dataItem,
                                                                                    node
                                                                                )
                                                                            }
                                                                        >
                                                                            {dataItem[node]}
                                                                        </a>
                                                                    )
                                                                        // :
                                                                        // this.props?.config?.inlineCheckbox?.includes(node) ?
                                                                        //     (
                                                                        //         <BasicCheckbox
                                                                        //             id={"cb-" + dataItem[node]}
                                                                        //             label={dataItem[node] || ""}
                                                                        //             checked={this.state.listEdits?.[rowIndex]?.[dataItem[node]]}
                                                                        //             className={"cb-" + dataItem[node]}
                                                                        //             onChange={(e) => this.props?.handleInlineCheckboxEdit((e.target.checked ? true : false), dataItem)}
                                                                        //         />
                                                                        //     ) 
                                                                        : this.props?.config?.highlightCell?.includes(
                                                                            node
                                                                        ) ? (
                                                                            <div
                                                                                className={this.props.selectedCellId === dataItem.revision ? "selected_cell" : ""}
                                                                                onClick={(e) =>
                                                                                    this.props.handleSelectedCell(
                                                                                        dataItem,
                                                                                        node
                                                                                    )
                                                                                }
                                                                            >
                                                                                {dataItem[node]}
                                                                            </div>
                                                                        )
                                                                            : this.props?.config?.toggleFields?.includes(
                                                                                node
                                                                            ) ? (
                                                                                dataItem[node] != null ? (
                                                                                    <MDBIcon
                                                                                        icon={
                                                                                            dataItem[node] == true
                                                                                                ? "check"
                                                                                                : "times"
                                                                                        }
                                                                                        className={
                                                                                            dataItem[node] == true
                                                                                                ? "text-success"
                                                                                                : "text-dark"
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <> </>
                                                                                )
                                                                            ) : this.props?.config?.nodeFields?.some(
                                                                                (configItem) => configItem.column === node) ?
                                                                                (this.props?.config?.nodeFields?.map((nodeItem) =>
                                                                                    (nodeItem.column === node) ?
                                                                                        this.props?.config?.tooltips?.includes(node) ?
                                                                                            <Tooltip clickable title={<div
                                                                                                dangerouslySetInnerHTML={{ __html: [dataItem[node]] }}
                                                                                            />}>
                                                                                                <span>
                                                                                                    <MDBIcon
                                                                                                        icon={nodeItem.icon}
                                                                                                        onClick={(nodeItem.column === "notes" || nodeItem.column === "inv_notes") ? this.props?.handlePopoverClick(dataItem, node) :
                                                                                                            nodeItem.icon === "flag" ? this.props?.handleFlagPopover :
                                                                                                                nodeItem.column === "efc" ? this.props?.handleEfcModal(node, dataItem) :
                                                                                                                    nodeItem.column === "bch" ? this.props?.handleBCHModal(node, dataItem) : null}
                                                                                                    />
                                                                                                </span>
                                                                                            </Tooltip> :
                                                                                            (nodeItem.icon === "pen-square" && (!unhideIcon && !unhideIconMS && ["song_cue_sheet_publisher_id", "song_performer_id", "song_licensor_id", "performer_licensor_id"].includes(nodeItem.column))
                                                                                            || (unhideIconMS && !unhideIcon && ["song_performer_id", "song_cue_sheet_publisher_id"].includes(nodeItem.column)))?
                                                                                            null :
                                                                                            // (nodeItem.icon === "pen-square" &&  (unhideIcon && ["song_cue_sheet_publisher_id", "song_performer_id", "song_licensor_id", "performer_licensor_id"].includes(nodeItem.column))
                                                                                            // || (unhideIconMS && ["song_licensor_id", "performer_licensor_id"].includes(nodeItem.column)))
                                                                                            // &&
                                                                                            <MDBIcon
                                                                                                icon={dataItem[node] ? nodeItem.icon : ""}
                                                                                                onClick={(nodeItem.column === "notes" || nodeItem.column === "inv_notes" || nodeItem.column === "episode_cost" || nodeItem.column === "episode_negotiated_costs") ? this.props?.handlePopoverClick(dataItem, node) :
                                                                                                    nodeItem.icon === "flag" ? this.props?.handleFlagPopover :
                                                                                                        nodeItem.column === "efc" ? this.props?.handleEfcModal(node, dataItem) :
                                                                                                            nodeItem.column === "bch" ? this.props?.handleBCHModal(node, dataItem) :
                                                                                                                nodeItem.icon === "pen-square" ? this.props?.handleEditPayeeModal(node, dataItem, rowIndex):null}
                                                                                            /> : <></>))
                                                                                : this.props?.config?.fileAttachments?.includes(
                                                                                    node
                                                                                ) ? (
                                                                                    <BasicButton
                                                                                        variant="outlined"
                                                                                        icon="paperclip"
                                                                                        type="inline"
                                                                                        inputType="file"
                                                                                        id={
                                                                                            this.props?.objectType +
                                                                                            "-" +
                                                                                            this.props?.name
                                                                                        }
                                                                                        uploadFile={this.props?.handleFileSelect}
                                                                                    />
                                                                                ) : (this.props?.isURM && node === 'season_id' ? (dataItem['searchSelectOptions']?.length > 0) : (this.state.selectOptionValues?.[node] && !this.props?.config?.searchFields?.find(
                                                                                    searchItem => searchItem?.label === node
                                                                                )))
                                                                                    ? (
                                                                                        this.props?.isURM && node === 'season_id' ?
                                                                                            <>
                                                                                                {dataItem['searchSelectOptions'].map(
                                                                                                    (item) => {
                                                                                                        if (item.value == dataItem[node])
                                                                                                            return <> {item?.label ? item.label : item.text} </>;
                                                                                                    }
                                                                                                )}
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {this.state.selectOptionValues?.[node].map(
                                                                                                    (item) => {
                                                                                                        if (item.value == dataItem[node])
                                                                                                            return <> {item?.label ? item.label : item.text} </>;
                                                                                                    }
                                                                                                )}
                                                                                            </>
                                                                                    ) : (this.props?.config?.searchFields?.find(
                                                                                        searchItem => searchItem?.label === node
                                                                                    )) ? (
                                                                                        <>
                                                                                            {/* {this.props?.list?.map(
                                                                                                (item) => {
                                                                                                    if (item[node] == dataItem[node]) */}
                                                                                                        {this.props?.config?.searchFields?.find(searchItem => searchItem?.label === node)?.hyperlink ?
                                                                                                            //return
                                                                                                             <a href="javascript:void(0);"
                                                                                                                onClick={(e) =>
                                                                                                                    this.props.hyperLinkNavigationCallback(
                                                                                                                        dataItem,
                                                                                                                        node
                                                                                                                    )
                                                                                                                }>{dataItem[node + '_name']}</a>
                                                                                                        :
                                                                                                            //return 
                                                                                                            <>{dataItem[node + '_name']}</>
                                                                                                        }
                                                                                                {/* }
                                                                                            )} */}
                                                                                        </>
                                                                                    ) : this.props?.config?.buttonFields?.some(
                                                                                        (configItem) => configItem.column === node)
                                                                                        ? (
                                                                                            (this.props?.config?.buttonFields?.map((nodeItem) =>
                                                                                                nodeItem.column === node ?
                                                                                                    <div className={nodeItem.disabled && (dataItem[node]) ? "table_disabled_button" : dataItem.is_fr === 0 ? "button-red" : dataItem.notes !== null && nodeItem.column === 'notes' ? "notes-blue" : "buttonField-label"}>
                                                                                                        <BasicButton
                                                                                                            variant="contained"
                                                                                                            // id={
                                                                                                            //     this.props?.objectType +
                                                                                                            //     "-" +
                                                                                                            //     this.props?.name
                                                                                                            // }
                                                                                                            id={node + "_" + dataItem[this.props.config.primaryKey] + "_" + rowIndex}
                                                                                                            onClick={nodeItem.column === "in_batch" ? () => this.props?.handleOpenBCHModal(node, dataItem) : this.props?.handleOpenInlineModal ?
                                                                                                                (event) => this.props?.handleOpenInlineModal(dataItem, nodeItem.column, rowIndex, event) :
                                                                                                                null
                                                                                                            }
                                                                                                            text={
                                                                                                                (nodeItem?.column && !nodeItem?.showDataNodeValue) ? (!nodeItem?.hideBtnText ? nodeItem.column : " ") :
                                                                                                                    ((nodeItem?.showDataNodeValue && dataItem[node]) ? dataItem[node] : " ")
                                                                                                            }
                                                                                                            icon={dataItem.is_fr === 0 ? "minus-circle" : nodeItem.icon === "" ? "" : nodeItem.icon}
                                                                                                            {...(nodeItem.disabled ? { disabled: dataItem[node] } : {})}
                                                                                                        />
                                                                                                    </div> : <></>
                                                                                            )
                                                                                            )
                                                                                        ) :
                                                                                        this.props?.config?.textWithbuttonFields?.some(
                                                                                            (configItem) => configItem.column === node)
                                                                                            ? (
                                                                                                (this.props?.config?.textWithbuttonFields?.map((nodeItem) =>
                                                                                                    nodeItem.column === node ?
                                                                                                        <div style={{ display: "flex" }}>
                                                                                                            <span>{nodeItem.column}</span>
                                                                                                            <div className="buttonField-label">
                                                                                                                <BasicButton
                                                                                                                    variant="contained"
                                                                                                                    id={
                                                                                                                        this.props?.objectType +
                                                                                                                        "-" +
                                                                                                                        this.props?.name
                                                                                                                    }
                                                                                                                    text={" "}
                                                                                                                    icon={nodeItem.icon}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : <></>
                                                                                                )
                                                                                                )
                                                                                            ) :
                                                                                            this.state.dataTypeValues?.[node] === "datefield" ?
                                                                                                (
                                                                                                    <>{dataItem[node] ? getFormattedDate(dataItem[node]) : ""}</>
                                                                                                ) :
                                                                                                this.props?.config?.inlineEdits?.find(iconCheck => iconCheck?.dataNode == node)?.inlineIcon ?
                                                                                                    (
                                                                                                        <>{`${this.props?.config?.inlineEdits?.find(iconCheck => iconCheck?.dataNode == node)?.inlineIcon} ${dataItem[node]}`}</>
                                                                                                    ) :
                                                                                                    (
                                                                                                        <>{dataItem[node]}</>
                                                                                                    )}
                                                                    {this.props?.config?.dataNodeGroups?.[node] ? (
                                                                        <>
                                                                            {this.props?.config?.dataNodeGroups?.[
                                                                                node
                                                                            ]?.map((item) =>
                                                                                dataItem["editing"] &&
                                                                                    this.props?.config?.inlineEdits?.some(e => e.dataNode === item) ? (
                                                                                    <> </>
                                                                                ) : (
                                                                                    <div>{dataItem[item]}</div>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <> </>
                                                                    )}

                                                                    {dataItem["editing"] &&
                                                                        this.props?.config?.inlineEdits?.some(e => e.dataNode === node) ?
                                                                        (
                                                                            <TableInlineEdits
                                                                                classNameForTable={this.props?.className}
                                                                                inlineObject={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)}
                                                                                handleOnlyIconColumn={this.handleOnlyIconColumn.bind(this)}
                                                                                idForFocus={this.props?.idForFocus || null}
                                                                                nodeName={node}
                                                                                fieldDisabled={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.fieldDisabled || false}
                                                                                placeholder={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.placeholder}
                                                                                validateMethod={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.validateMethod}
                                                                                validateMessage={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.validateMessage}
                                                                                inputType={this.state.dataTypeValues[node]}
                                                                                inputValue={
                                                                                    this.state.listEdits?.[rowIndex]?.[node]
                                                                                }
                                                                                index={rowIndex}
                                                                                handleInlineEditChange={this.handleInlineEditChange.bind(
                                                                                    this
                                                                                )}
                                                                                inlineIconClick={() => this.props?.inlineIconClick(node, rowIndex)}
                                                                                selectOptions={
                                                                                    this.props?.isURM && node === 'season_id' ? dataItem['searchSelectOptions'] : this.state.selectOptionValues?.[node]
                                                                                }
                                                                                searchString={this.props?.searchString}
                                                                                icon={node === "is_fr" ? "plus-circle" : this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.icon}
                                                                                inlineIcon={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.inlineIcon}
                                                                                tooltip={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.iconTooltip}
                                                                                division_id={this.props?.division_id}
                                                                            />
                                                                        ) : (
                                                                            <> </>
                                                                        )}

                                                                    {this.props?.config?.dataNodeGroups?.[node] ? (
                                                                        <>
                                                                            {this.props?.config?.dataNodeGroups?.[
                                                                                node
                                                                            ]?.map((item) =>
                                                                                dataItem["editing"] &&
                                                                                    this.props?.config?.inlineEdits?.some(e => e.dataNode === item) ? (
                                                                                    <TableInlineEdits
                                                                                        classNameForTable={this.props?.className}
                                                                                        inlineObject={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)}
                                                                                        handleOnlyIconColumn={this.handleOnlyIconColumn.bind(this)}
                                                                                        idForFocus={this.props?.idForFocus || null}
                                                                                        nodeName={item}
                                                                                        fieldDisabled={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.fieldDisabled || false}
                                                                                        placeholder={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.placeholder}
                                                                                        validateMethod={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.validateMethod}
                                                                                        validateMessage={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.validateMessage}
                                                                                        inputType={"text"}
                                                                                        inputValue={
                                                                                            this.state.listEdits?.[rowIndex]?.[
                                                                                            item
                                                                                            ]
                                                                                        }
                                                                                        index={rowIndex}
                                                                                        handleInlineEditChange={this.handleInlineEditChange.bind(
                                                                                            this
                                                                                        )}
                                                                                        inlineIconClick={() => this.props?.inlineIconClick(node, rowIndex)}
                                                                                        searchString={this.props?.searchString}
                                                                                        icon={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.icon}
                                                                                        inlineIcon={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.inlineIcon}
                                                                                        tooltip={this.props?.config?.inlineEdits?.find(e => e.dataNode === node)?.iconTooltip}
                                                                                    />
                                                                                ) : (
                                                                                    <> </>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <> </>
                                                                    )}
                                                                </TableCell>
                                                            )
                                                        )}
                                                        {this.props?.config?.actions && (
                                                            <TableCell
                                                                key={this.props.className + "action"}
                                                                className={"border-ver-1-secondary"}
                                                            >
                                                                {" "}
                                                                <div className="row">
                                                                    <div className="minus-align">
                                                                        {dataItem["editing"] ? this.props?.removeMinus ? null : <MDBIcon className='minusIconFortable'icon="minus" onClick={() => this.props?.readCallback(rowIndex)} /> : null}
                                                                        {
                                                                            // dataItem.add ?
                                                                            //     <div key={this.props.className}
                                                                            //         className="col-md padding-3">
                                                                            //         <MDBIcon
                                                                            //             icon={dataItem.add}
                                                                            //             className="action-icon"
                                                                            //         />
                                                                            //     </div> :
                                                                            this.props?.config?.actions?.map(
                                                                                (action, indexNo) => (
                                                                                    <div
                                                                                        id={dataItem[this.props.config.primaryKey]+action+indexNo}
                                                                                        key={this.props.className + "-" + indexNo}
                                                                                        className="col-md padding-3 plusCircleFontSize"
                                                                                        disabled={
                                                                                            this.props?.tabValue === 1
                                                                                                ? !dataItem?.is_edit
                                                                                                : false
                                                                                        }
                                                                                        onClick={(e) =>
                                                                                            action === "share" ?
                                                                                                this.props?.handleOpenPopUp(dataItem[this.props.config.primaryKey], dataItem)
                                                                                                :
                                                                                                action === "pen"
                                                                                                    ? dataItem["editing"]
                                                                                                        ? this.props?.saveCallback(
                                                                                                            this.state.listEdits,
                                                                                                            rowIndex,
                                                                                                            dataItem[
                                                                                                            this.props.config.primaryKey
                                                                                                            ],
                                                                                                            dataItem
                                                                                                        )
                                                                                                        : this.props?.editCallback(
                                                                                                            dataItem[
                                                                                                            this.props.config.primaryKey
                                                                                                            ],
                                                                                                            dataItem,
                                                                                                            rowIndex
                                                                                                        )
                                                                                                    : action === "plus"
                                                                                                        ? this.props?.handleAddCallback(
                                                                                                            dataItem[
                                                                                                            this.props.config.primaryKey
                                                                                                            ]
                                                                                                        )
                                                                                                        : (action === "trash-alt" || action === "times")
                                                                                                            ? this.props?.handleDelCallBack(
                                                                                                                dataItem[
                                                                                                                this.props.config.primaryKey
                                                                                                                ],
                                                                                                                dataItem,
                                                                                                                rowIndex
                                                                                                            )
                                                                                                            : action === "arrows-alt-v"
                                                                                                                ? this.props?.handlepopupCallback(
                                                                                                                    dataItem[
                                                                                                                    this.props.config.primaryKey
                                                                                                                    ],
                                                                                                                    dataItem,
                                                                                                                    rowIndex,
                                                                                                                    e
                                                                                                                )
                                                                                                                : action === "copy"
                                                                                                                    ? this.props?.handleInlineCopy(
                                                                                                                        dataItem[this.props.config.primaryKey],
                                                                                                                    ) : null
                                                                                        }
                                                                                    >
                                                                                        {action === "view" ? (
                                                                                            <a href="#">{action}</a>
                                                                                        ) : action === "pen" &&
                                                                                            dataItem["editing"] ? (
                                                                                            <MDBIcon
                                                                                                icon={"save"}
                                                                                                color="primary"
                                                                                                className="action-icon"
                                                                                            />
                                                                                        ) :
                                                                                            (
                                                                                                <MDBIcon
                                                                                                    icon={action}
                                                                                                    className="action-icon"
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                            ))}
                                        {this.props?.list?.length <= 0 && (
                                            <TableRow>
                                                <TableCell
                                                    className="text-align-center"
                                                    colSpan={this.props?.config?.headings?.length}
                                                >
                                                    <b>No results</b>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>

                    </div>
                    {!this.props?.config?.disableSelectCheckbox && this.props?.config?.newRowSelectOptions && this.props?.config?.newRowSelectOptions?.some(checkLength => checkLength?.addRowOptions?.length > 0) && <div className="selectFieldsNewRow mt-1">
                        {this.props?.config?.newRowSelectOptions?.map(newSelect => (
                            <>
                                {newSelect?.inputType === 'select' &&
                                    newSelect?.addRowOptions?.length > 0 ? <SelectField
                                    id={newSelect?.dataNode}
                                    placeHolderText={newSelect?.placeHolderText}
                                    options={newSelect?.addRowOptions || []}
                                    width={newSelect?.width}
                                    onChange={(e) => {
                                        let newItem = { ...this.state.newItem }
                                        let selectedNewItem = newSelect?.addRowOptions?.find(addRowItem => addRowItem?.value === e.target?.value)
                                        newItem[newSelect?.onchangeField] = selectedNewItem?.label
                                        if (newSelect?.relatedField && newSelect?.relatedField?.length > 0) {
                                            newSelect?.relatedField?.map(relatedField => {
                                                newItem[relatedField] = selectedNewItem[relatedField]
                                            })
                                        }
                                        this.setState({ newItem: newItem })
                                    }}
                                /> : <div style={{ width: newSelect?.width ? newSelect?.width : "" }}></div>}
                                {newSelect?.inputType === 'none' && <div style={{ width: newSelect?.width ? newSelect?.width : "" }}></div>}
                            </>
                        ))}
                    </div>}
                    {this.props?.addItemToList ?
                        this.props?.postInitiated === 'newItemPost' ? <CircularProgress size={20} /> :
                            <div className="inlineAdd" id="table-buttoncolor">
                                {this.props?.config?.inlineEdits?.map((nodeItem, nodeIndex) =>{ 
                                    const newItem=this.state?.newItem
                                    const searchSelectValue=newItem && (newItem["licensor"] || newItem["performer"] || newItem["cue_sheet_publisher"] || newItem["performer_licensor"])
                                    return(
                                    <TableInlineEdits
                                        classNameForTable={this.props?.className}
                                        newRowForTable={true}
                                        inlineObject={nodeItem}
                                        handleOnlyIconColumn={this.handleOnlyIconColumn.bind(this)}
                                        fieldDisabled={false}
                                        newIsActive={this.props?.newIsActive}
                                        placeholder={nodeItem?.placeholder}
                                        validateMethod={nodeItem?.validateMethod}
                                        validateMessage={nodeItem?.validateMessage}
                                        className={nodeItem?.className}
                                        nodeName={nodeItem?.dataNode}
                                        inputType={this.state.dataTypeValues[nodeItem?.dataNode]}
                                        inputValue={this.state?.newItem?.[nodeItem?.dataNode]}
                                        width={nodeItem.width}
                                        icon={nodeItem.icon}
                                        inlineIcon={nodeItem?.inlineIcon}
                                        tooltip={nodeItem.iconTooltip}
                                        index={nodeIndex}
                                        handleInlineEditChange={this.onChangeNewItemHandler}
                                        inlineIconClick={() => this.props?.inlineIconClick(nodeItem?.dataNode)}
                                        selectOptions={
                                            this.state.selectOptionValues?.[nodeItem?.dataNode]?.filter(item => 
                                                nodeItem?.dataNode !== "affiliation" && item?.hasOwnProperty('is_active')? item?.is_active === 1 : true)
                                        }
                                        searchString={this.props?.searchString}
                                        isFetchingSeason={this.state.isFetchingSeason}
                                        isURM={this.props?.isURM}
                                        division_id={this.props?.division_id}
                                        visibleIcon={!searchSelectValue}
                                    />
                                )})
                                }
                                {this.props?.isURM ?
                                    this.state.isFetchingSeason ?
                                        null : <div className={'plusCircleFontSize'}><MDBIcon
                                            icon={"plus-circle"}
                                            onClick={this.handleAddNewItem}
                                        /></div> :
                                    <div className={'plusCircleFontSize'}><MDBIcon
                                        icon={"plus-circle"}
                                        onClick={this.handleAddNewItem}
                                    /></div>}
                                {this.props?.showClear &&
                                    <div className="clear-btn">
                                        <BasicButton
                                            variant="text"
                                            text="Clear"
                                            onClick={() => this.setState({ newItem: {} })}
                                        />
                                    </div>
                                }
                            </div>
                        : ""
                    }
                </div>
                {
                    this.state.openPayeeModal &&
                    <MainViewModalComponent
                        getPayeeRemitTo={this.getPayeeRemitTo.bind(this)}
                        open={this.state.openPayeeModal ? true : false}
                        handleClose={() => this.setState({ openPayeeModal: null })}
                        headerText={"Payee"}
                        mode={this.state.openPayeeModal?.split('-')[0] === 'new' ? 'New' : 'Edit'}
                        modalName={"Payee"}
                        modeType="CLEARANCE_PAYEE"
                        id={this.state.openPayeeModal?.split('-')[0] === 'new' ? null : this.state.openPayeeModal?.split('-')[0]}
                        commonPermissions={this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_name === "Clearance Payees")}
                    />
                }
                {this.state.openSyncLicensorModal && (
                        <MainViewModalComponent
                            open={this.state.openSyncLicensorModal ? true : false}
                            handleClose={() => {this.setState({openSyncLicensorModal:null})}}
                            headerText={"Licensor"}
                            mode={this.state.openSyncLicensorModal?.split('-')[0] === 'new' ? 'New' : 'Edit'}
                            modalName={"Edit Licensor"}
                            division_id={this.props?.division_id}
                            id={this.state.openSyncLicensorModal?.split('-')[0] === 'new' ? null : this.state.openSyncLicensorModal?.split('-')[0]}
                            songModalUpdate={(licensorData) => this.onFlyUpdate(licensorData,this.state.openSyncLicensorModal)}
                            commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
                        />
                )}
                {this.state.openCueSheetPubModal && <MainViewModalComponent
                                open={this.state.openCueSheetPubModal ? true : false}
                                handleClose={() => {this.setState({openCueSheetPubModal:null})}}
                                headerText={"Cue Sheet Publisher"}
                                mode={this.state.openCueSheetPubModal?.split('-')[0] === 'new' ? 'New' : 'Edit'}
                                cueSheetPubId={this.state.openCueSheetPubModal?.split('-')[0] === 'new' ? null : this.state.openCueSheetPubModal?.split('-')[0]}
                                modalName={"On Fly Lookup"}
                                type={'cueSheetPublisher'}
                                handleCueSheetPubUpdate={(cueSheetPubInfo) => {
                                    this.onFlyUpdate(cueSheetPubInfo,this.state.openCueSheetPubModal)
                                }}
                />}
                {this.state.openPerformerModal && <MainViewModalComponent
                                open={this.state.openPerformerModal ? true : false}
                                handleClose={() => {this.setState({openPerformerModal:null})}}
                                headerText={"Performer"}
                                mode={this.state.openPerformerModal?.split('-')[0] === 'new' ? 'New' : 'Edit'}
                                performerId={this.state.openPerformerModal?.split('-')[0] === 'new' ? null : this.state.openPerformerModal?.split('-')[0]}
                                modalName={"On Fly Lookup"}
                                type={'performer'}
                                handlePerformerUpdate={(cueSheetPubInfo) => {
                                    this.onFlyUpdate(cueSheetPubInfo,this.state.openPerformerModal)
                                }}
                />}
            </>
        );
    }
}

export default withUserContext(TableComponent);
