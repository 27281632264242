export const writersIntialDetails = {
    "writer_id": null,
    "writer_name": null,
    "affiliation_id": null,
    "is_active": 0

}
export const affilation = [
    { value: 1, label: "affilation" },
]

export const AffiliationInialDetails ={
    "affiliation_id": null,
    "affiliation_name": null,
    "is_active": 1, 
}
export const performerIntialDetails = {
    "date_created": null,
    "is_active": null,
    "id": null,
    "name": null
}

export const pdComposerIntialDetails = {
    "pd_composer_id": null,
    "pd_composer_name": null,
    "year_died": null,
    "comment": null,
    "is_active": 1
}


export const cueSheetPublisherIntialDetails = {
    "id": null,
    "name": null,
    // "date_created": null,
    // "is_active": null,
}

export const cueSheetPublisherConfig = {
    headings: [
        {
            headingLabel: "Show/Season",
            width: "19%"
        },
        {
            headingLabel: "Episode#",
            width: "15%"
        },
        {
            headingLabel: "Song",
            width: "28%"
        },
        {
            headingLabel: "Use",
            width: "15%"
        },
        {
            headingLabel: "Status",
            width: "10%"
        },
        {
            headingLabel: "Intial Fee",
            width: "8%"
        },


    ],
    dataNodes: ["show_season", "episode_title", "song_title", "licensor_name", "status", "initial_fee"],
    primaryKey: "clearance_id",
    // actions: ["share"],
    hyperlinks: ["show_season", "episode_title"]
};

export const writersConfig = {
    headings: [
        {
            headingLabel: "Show/Season",
            width: "19%"
        },
        {
            headingLabel: "Episode#",
            width: "13%"
        },
        {
            headingLabel: "Song",
            width: "28%"
        },
        {
            headingLabel: "Use",
            width: "15%"
        },
        {
            headingLabel: "Status",
            width: "10%"
        },
        {
            headingLabel: "Intial Fee",
            width: "10%"
        },


    ],
    dataNodes: ["show_season", "episode_number", "song_title", "abbreviation", "status", "initial_fee"],
    primaryKey: "clearance_id",
    // actions: ["share"],
    hyperlinks: ["show_season", "episode_number"]
};
export const affiliationConfig = {
    headings: [
        {
            headingLabel: "Show/Season",
            width: "19%"
        },
        {
            headingLabel: "Episode#",
            width: "13%"
        },
        {
            headingLabel: "Song",
            width: "28%"
        },
        {
            headingLabel: "Use",
            width: "15%"
        },
        {
            headingLabel: "Status",
            width: "10%"
        },
        {
            headingLabel: "Intial Fee",
            width: "10%"
        },


    ],
    dataNodes: ["show_season", "episode_title", "song_title", "abbreviation", "status", "initial_fee"],
    primaryKey: "clearance_id",
    // actions: ["share"],
    hyperlinks: ["show_season", "episode_title"]
};

export const prformerGridConfig = {
    headings: [
        {
            headingLabel: "Show/Season",
            width: "19%"
        },
        {
            headingLabel: "Episode#",
            width: "15%"
        },
        {
            headingLabel: "Song",
            width: "26%"
        },
        {
            headingLabel: "Use",
            width: "15%"
        },
        {
            headingLabel: "Status",
            width: "10%"
        },
        {
            headingLabel: "Intial Fee",
            width: "10%"
        },
        {   
            headingLabel: " ",
            icon:"",
            width:"5%"
        }


    ],
    dataNodes: ["show_season", "episode_title", "song_title", "abbreviation", "status", "initial_fee"],
    primaryKey: "clearance_id",
    actions: ["share"],
    hyperlinks: ["show_season", "episode_title"]
};

export const pdComposerConfig = {
    headings: [
        {
            headingLabel: "Show/Season",
            width: "19%"
        },
        {
            headingLabel: "Episode#",
            width: "15%"
        },
        {
            headingLabel: "Song",
            width: "28%"
        },
        {
            headingLabel: "Use",
            width: "15%"
        },
        {
            headingLabel: "Status",
            width: "10%"
        },
        {
            headingLabel: "Intial Fee",
            width: "10%"
        },


    ],
    dataNodes: ["show_season", "episode_title", "song_title", "licensor_name", "status", "initial_fee"],
    primaryKey:  "clearance_id",
    // actions: ["share"],
    hyperlinks: ["show_season", "episode_title"]
};