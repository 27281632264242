import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { composerConfig } from './config';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import messages from '../../../../Common/Messages.json';
import * as Constants from '../../../../constants/constants';
import ClearTrackService from '../../../../services/service';
import './Composer.scss';
import axios from 'axios';
import { withUserContext } from '../../../../contexts/UserContext';
import {validateNumDotOnly } from "../../../../Common/Helper";

class Composer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            composers: this.props?.selectedComposerInfo,
            composerOptions: [],
            affiliationOptions: [],
            composerIndex: 2,
            postInitiated: false,
            searchCancelToken: null,
            isFetchingComposer: false
        }
    }

    componentDidMount() {
        this.getStaticData('affiliationOptions', 'lu_affiliation');
    }

    formatComposerList = () => {
        let composers = [];
        if (composers?.length === 0) {
            composers.push(JSON.parse(JSON.stringify({ ...composerConfig })));
        }
        return composers;
    }

    getEntity = (entity, entityType, searchString) => {
        if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken, isFetchingComposer: true });
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`, cancelToken,  this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (entityType === "PD_COMPOSER") {
                    let composers = response?.data?.map(item => ({
                        text: item.pd_composer_name,
                        value: item.pd_composer_id,
                    }));
                    this.setState({ [entity]: composers, isFetchingComposer: false })
                }
            })
    }

    getStaticData = (fieldName, dataType) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${dataType}`, null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item?.is_active }));
                let composer = this.state.composers?.map((item) => {
                    item.selected_affiliation = {value: formattedList?.find(val => val.text == item.selected_affiliation).value, text: item.selected_affiliation}
                    return item;
                })
                this.setState({ [fieldName]: formattedList, composers: composer });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    canSubmit = (composers) => {
        let canSubmit = (!composers?.some(composer => (!(composer.composer_name && composer.affiliation_id && composer.percentage) || false)))
        return canSubmit;
    }


    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let composers = [...this.state.composers];
        if (this.canSubmit(composers)) {
            this.setState({ isPosting: true })
            let formattedComposerNames = [];
            this.state.composers?.map(item => {
                let data = null;
                if (/^\d+(\.\d+)?%$/.test(item.percentage)) {
                   data = item.percentage
                } else {
                    data = item.percentage + "%"
                }
                let composerName = item.composer_name + ' (' + item.affiliation_name + ') ' + data;
                formattedComposerNames.push(composerName);
            })
            let convertedComposerName = formattedComposerNames.join('\r\n');

            this.props?.handleNodeValue(convertedComposerName);
            this.props.handleClose();
        }
    }

    handleOnChange = (fieldArray, field, value, index) => {
        let composers = [...this.state.composers];
        // if (field === "composer"  ) {
        //     composers[index][field + '_id'] = value?.value || null;
        //     composers[index][field + '_name'] = value?.text || null;
        //     composers[index]["selected_composer"] = value || null;
         if (field === "affiliation") {
            composers[index][field + '_id'] = value?.value || null;
            composers[index][field + '_name'] = value?.text || null;
            composers[index]["selected_affiliation"] = value || null
        }
        else {
            composers[index][field] = value || null;
        }
        this.setState({
            composers: composers,
            postInitiated: false
        }, () => {
            this.setState({ composerOptions: [] })
        })
    };

    addAdditionalField = (listName) => {
        this.setState({ postInitiated: false });
        let initialComposerArray = JSON.parse(JSON.stringify({ ...composerConfig }));
        let composers = [...this.state.composers];
        initialComposerArray.index = this.state.composerIndex;
        initialComposerArray.canRemove = true;
        composers.push(initialComposerArray)
        this.setState({ composerIndex: this.state.composerIndex + 1, composers: composers });
    }

    removeField = (listName, callbackItem, index) => {
        this.setState({
            listName: listName, callbackItem: callbackItem, postInitiated: false, index: index
        }, () => {
            let composers = [...this.state.composers];
            if (!callbackItem.canRemove) {
                let index = composers.indexOf(callbackItem);
                if (index > -1) {
                    composers?.splice(index, 1);
                }
            } else {
                let list = composers?.filter(item => item.index !== callbackItem.index);
                composers = list;
            }
            this.setState({ composers: composers });
        })
    }

    render() {
        return (
            <MDBContainer className="composerContainer">
                {this.state.composers?.map((composer, index) =>
                    <MDBRow>
                        <MDBCol md={5} className="p-0 composer">
                            <BasicTextField
                                id={"composer"}
                                label={index === 0 ? "Composer" : ""}
                                placeholder={"composer"}
                                width={"100%"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated}
                                mandatoryWarning={messages.mandatoryWarning}
                                value={composer.composer_name || ""}
                                onChange={(e) => this.handleOnChange('composers', 'composer_name', e.target.value?.length > 0 ? e.target?.value : null, index)}
                            />
                        </MDBCol>
                        <MDBCol md={4} className="p-0 affiliation">
                            <SearchSelectField
                                id={"affiliation-select"}
                                width={"100%"}
                                label={index === 0 ? "Affiliation" : ""}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated}
                                mandatoryWarning={messages.mandatoryWarning}
                                // value={{ value: composer.affiliation_id, text: composer.affiliation_name } || {}}
                                value ={ composer.selected_affiliation || {}}
                                options={this.state.affiliationOptions || []}
                                placeholder={"- Select -"}
                                onChange={(e, value) => this.handleOnChange('composers', 'affiliation', value, index)}
                            />
                        </MDBCol>
                        <MDBCol md={2} className="p-0 percentage">
                            <BasicTextField
                                id={"PctText"}
                                label={index === 0 ? "Pct." : ""}
                                placeholder={"__.__"}
                                width={"100%"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated}
                                mandatoryWarning={messages.mandatoryWarning}
                                value={composer.percentage || ""}
                                onChange={(e) =>validateNumDotOnly(e.target.value) ? this.handleOnChange('composers', 'percentage', e.target.value, index) : null}
                            />
                        </MDBCol>
                        <MDBCol md={1} className="p-0 actionIcons">
                            <MDBRow className={index === 0 ? "actionButons ml-1" : "ml-1"}>
                                <MDBCol md={1} className="actions iconWidth p-0">
                                    {
                                        !(this.state.composers?.length === 1) ?
                                            <RemoveCircleIcon
                                                fontSize="small"
                                                onClick={(e) => this.removeField('composers', composer, index)}
                                            /> : null
                                    }
                                </MDBCol>
                                <MDBCol md={1} className="actions">
                                    {
                                        (this.state.composers?.length - 1 === index && composer.composer_name) ?
                                            <AddCircleIcon fontSize="small"
                                                onClick={(e) =>
                                                    this.addAdditionalField('composers', index)
                                                }
                                            /> : null
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                )}
                <MDBRow className='mt-4' >
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={4}>
                        <MDBRow>
                            <MDBCol>
                                <BasicButton
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                    onClick={this.handleSubmit}
                                />
                            </MDBCol>
                            <MDBCol>
                                <BasicButton
                                    onClick={() => this.props.handleClose()}
                                    variant="outlined"
                                    text={"Cancel"}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}
export default withUserContext(Composer);